// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"0ce936a6ddc7e79c5c73198012911c935a3fe1b0"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import * as Sentry from '@sentry/nextjs'

Sentry.init({
	dsn: 'https://08599a5b4c197fbcfc4d69fc20d84d33@o4507692016533504.ingest.us.sentry.io/4507847936114688',

	// Adjust this value in production or use tracesSampler for greater control
	tracesSampleRate: 1,

	// Setting this option to true will print useful information to the console while you're setting up Sentry.
	debug: false,
	replaysOnErrorSampleRate: 1,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// You can remove this option if you're not planning to use the Sentry Session Replay feature:
	integrations: [
		Sentry.replayIntegration({
			// Additional Replay configuration goes in here, for example:
			maskAllText: true,
			blockAllMedia: true,
		}),
		Sentry.browserTracingIntegration(),
		Sentry.browserProfilingIntegration(),
	],
	tracePropagationTargets: [
		'localhost',
		/^\//,
	],
})
